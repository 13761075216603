<template>
    <div class="all-box">
        <div class="top-all-box-btn">
            <TopBtn/>
        </div>
        <div class="all-main-box-all">
            <div class="top-box">
                <p class="p-title">主管信息维护</p>
                <div class="seting-box">
                    <div class="btn-box" @click="clickAdd">新增</div>
                </div>
            </div>
            <div class="bom-box">
                <div class="head-box">
                    <!-- <div class="common-lis common-lis1">
                        <div class="common-main">ID</div>
                    </div> -->
                    <div class="common-lis common-lis2">
                        <div class="common-main">员工ID</div>
                    </div>
                    <div class="common-lis common-lis3">
                        <div class="common-main">员工姓名</div>
                    </div>
                    <div class="common-lis common-lis4">
                        <div class="common-main">主管ID</div>
                    </div>
                    <div class="common-lis common-lis5">
                        <div class="common-main">主管姓名</div>
                    </div>
                    <div class="common-lis common-lis6">
                        <div class="common-main common-main-s">操作</div>
                    </div>
                </div>
                <div class="table-box">
                    <div class="table-list" v-show="!showLoading">
                        <div class="table-list-main" v-show="!showKong">
                            <div class="table-center-all">
                                <div class="table-lis" v-for="(item,index) in dataList" :key="item.id">
                                    <div class="common-lis common-lis2">
                                        <span>{{item.staffId}}</span>
                                    </div>
                                    <div class="common-lis common-lis3">
                                        <span>{{item.staffName}}</span>
                                    </div>
                                    <div class="common-lis common-lis4">
                                        <span>{{item.managerId}}</span>
                                    </div>
                                    <div class="common-lis common-lis5">
                                        <span>{{item.managerName}}</span>
                                    </div>
                                    <div class="common-lis common-lis6">
                                        <span class="btn-edit" @click="clickEdit(item)">编辑</span>
                                        <span class="btn-del" @click="clickDel(item)">删除</span>
                                    </div>
                                </div>
                            </div>
                            <div class="table-fenye">
                                <el-pagination class="fenye-box" @size-change="handleSizeChange" :page-sizes="[20, 50, 100, 200]"  @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="total,sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                            </div>
                        </div>
                        
                        <div class="kong-box" v-show="showKong">
                            <img src="../assets/imgs/zanwushuju.png" alt="" class="img-kong" >
                            <p>暂无数据 </p>
                        </div>
                    </div>
                    <div class="loading-box" v-show="showLoading">
                        <div class="content" >
                            <div class="point1"></div>
                            <div class="point2"></div>
                            <div class="point3"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog class="mannage-dialog" title="新增数据" :visible.sync="dialogVisible" width="35%" :before-close="handleClose">
            <div class="text-box">
                <div class="p-info">
                    <div class="span-left">员工ID</div>
                    <div class="span-right">
                        <el-input v-model="inputVal1" placeholder="请输入员工ID"></el-input>
                    </div>
                </div>
                <div class="p-info">
                    <div class="span-left">员工姓名</div>
                    <div class="span-right">
                        <el-input v-model="inputVal2" placeholder="请输入员工姓名"></el-input>
                    </div>
                </div>
                <div class="p-info">
                    <div class="span-left">主管ID</div>
                    <div class="span-right">
                        <el-input v-model="inputVal3" placeholder="请输入主管ID"></el-input>
                    </div>
                </div>
                <div class="p-info">
                    <div class="span-left">主管姓名</div>
                    <div class="span-right">
                        <el-input v-model="inputVal4" placeholder="请输入主管姓名"></el-input>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="clickOk">确 定</el-button>
            </span> 
        </el-dialog>

        <el-dialog class="mannage-dialog" title="编辑数据" :visible.sync="dialogVisibleEdit" width="35%" :before-close="handleCloseEdit">
            <div class="text-box">
                <div class="p-info">
                    <div class="span-left">员工ID</div>
                    <div class="span-right">
                        <el-input v-model="inputEdit1" placeholder="请输入员工ID"></el-input>
                    </div>
                </div>
                <div class="p-info">
                    <div class="span-left">员工姓名</div>
                    <div class="span-right">
                        <el-input v-model="inputEdit2" placeholder="请输入员工姓名"></el-input>
                    </div>
                </div>
                <div class="p-info">
                    <div class="span-left">主管ID</div>
                    <div class="span-right">
                        <el-input v-model="inputEdit3" placeholder="请输入主管ID"></el-input>
                    </div>
                </div>
                <div class="p-info">
                    <div class="span-left">主管姓名</div>
                    <div class="span-right">
                        <el-input v-model="inputEdit4" placeholder="请输入主管姓名"></el-input>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialogEdit">取 消</el-button>
                <el-button type="primary" @click="clickOkEdit">确 定</el-button>
            </span> 
        </el-dialog>
    </div>
</template>
        
<script>
import * as api from "../api"
import TopBtn from "./components/topBtn.vue"
export default {
   data() {
    return {
        showLoading:true,
        showKong:false,
        dataList:[],

        dialogVisible:false,
        dialogVisibleEdit:false,

        inputVal1:'',
        inputVal2:'',
        inputVal3:'',
        inputVal4:'',

        inputEdit1:'',
        inputEdit2:'',
        inputEdit3:'',
        inputEdit4:'',
        editDd:'',


        visibleOut:false,
        visibleOver:false,
        dateVal1:'',
        dateVal2:'',
        startTime1:'',
        endTime1:'',

        startTime2:'',
        endTime2:'',


        visibleTixing1:false,
        dateValTixing1:'',



        visibleTixing2:false,

        pageSize:20,
        total:0,   
        currentPage:1, 

        
    }
   },
   components: {
        TopBtn
   },
   mounted() {
        this.getData()
   },
   methods: {
        handleCurrentChange(i){
            this.currentPage = i
            this.getData()
        },
        handleSizeChange(i){
            this.currentPage = 1
            console.log(i);
            this.pageSize = i
            this.getData()
        },
        getData(){
            this.showLoading = true
            this.dataList = []
            this.showKong = false
            api.getKeepApi(this.currentPage,this.pageSize).then(res=>{
                console.log(res.data,"aaa");
                this.total = Number(res.data.total)
                if(res){
                    this.showLoading = false
                    if(res.data.rows && res.data.rows.length > 0){
                        this.showKong = false
                        this.dataList = res.data.rows
                    }else{
                        this.showKong = true
                        this.dataList = []
                    }
                }else{
                    this.showLoading = true
                }
            })
        },
        clickAdd(){
            this.titleText = '新增数据'
            this.dialogVisible = true
        },
        closeDialog(){
            this.dialogVisible = false
            this.inputVal1 = ''
            this.inputVal2 = ''
            this.inputVal3 = ''
            this.inputVal4 = ''
        },
        handleClose(){
            this.dialogVisible = false
            this.inputVal1 = ''
            this.inputVal2 = ''
            this.inputVal3 = ''
            this.inputVal4 = ''
        },
        
        clickOk(){
            let params = {
                staffId:this.inputVal1.trim(),
                staffName:this.inputVal2.trim(),
                managerId:this.inputVal3.trim(),
                managerName:this.inputVal4.trim(),
            }
            api.getKeepAddApi(params).then(res=>{
                console.log(res.data,"vvv");
                if(res.data.msg === '操作成功'){
                    this.$message.success('新增成功!')
                    this.dialogVisible = false
                    this.inputVal1 = ''
                    this.inputVal2 = ''
                    this.inputVal3 = ''
                    this.inputVal4 = ''
                    this.getData()
                }else{
                    this.$message.error('新增失败!')
                    this.dialogVisible = false
                    this.inputVal1 = ''
                    this.inputVal2 = ''
                    this.inputVal3 = ''
                    this.inputVal4 = ''
                }
            })
        },

        clickEdit(item){
            this.dialogVisibleEdit = true
            this.editDd = item.id
            this.inputEdit1 = item.staffId
            this.inputEdit2 = item.staffName
            this.inputEdit3 = item.managerId
            this.inputEdit4 = item.managerName
        },
        handleCloseEdit(){
            this.dialogVisibleEdit = false
            this.inputEdit1 = ''
            this.inputEdit2 = ''
            this.inputEdit3 = ''
            this.inputEdit4 = ''
        },
        closeDialogEdit(){
            this.dialogVisibleEdit = false
            this.inputEdit1 = ''
            this.inputEdit2 = ''
            this.inputEdit3 = ''
            this.inputEdit4 = ''

            
        },
        clickOkEdit(){
            // this.dialogVisibleEdit = false
            let params = {
                id:this.editDd,
                staffId:this.inputEdit1.trim(),
                staffName:this.inputEdit2.trim(),
                managerId:this.inputEdit3.trim(),
                managerName:this.inputEdit4.trim(),
            }
            api.getKeepEditApi(params).then(res=>{
                console.log(res.data,"sss");
                if(res.data.msg === '操作成功'){
                    this.$message.success('编辑成功!')
                    this.dialogVisibleEdit = false
                    this.getData()
                }else{
                    this.$message.error('编辑失败!')
                    this.dialogVisibleEdit = false
                }
            })
            
        },
        clickDel(item){
            console.log(item);
            this.$confirm('您确定要删除此条数据吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                api.getKeepDelApi(item.id).then(res=>{
                    console.log(res.data,"sss");
                    if(res.data.msg === '操作成功'){
                        this.$message.success('删除成功!')
                        this.getData()
                    }else{
                        this.$message.error('删除失败!')
                    }
                })
            }).catch(() => {
                      
            });
            
        },

        clickOut(){
            this.visibleOut = true
        },
        clickOver(){
            this.visibleOver = true
        },

        handleCloseOut(){
            this.visibleOut = false
        },
        handleCloseOver(){
            this.visibleOver = false
        },
        closeDialogOut(){
            this.visibleOut = false
        },
        closeDialogOver(){
            this.visibleOver = false
        },
        clickOkOut(){
            console.log(this.startTime1);
            console.log(this.endTime1);
            if(this.startTime1 === '' || this.endTime1 === ''){
                this.$message.warning('请先选择日期区间')
            }else{
                api.getOutShuaApi(this.startTime1,this.endTime1).then(res=>{
                    console.log(res);
                    this.$message.success('正在后台刷取数据!')
                    this.visibleOut = false
                    this.dateVal1 = ''
                })
            }
        },
        clickOkOver(){
            console.log(this.startTime2);
            console.log(this.endTime2);
            if(this.startTime2 === '' || this.endTime2 === ''){
                this.$message.warning('请先选择日期区间')
            }else{
                api.getOverShuaApi(this.startTime2,this.endTime2).then(res=>{
                    console.log(res);
                    this.$message.success('正在后台刷取数据!')
                    this.visibleOver = false
                    this.dateVal2 = ''
                })
            }
        },
        fillZero(str){
            var realNum;
            if(str<10){
                realNum	= '0'+str;
            }else{
                realNum	= str;
            }
            return realNum;
        },
        changeHandlerDate1(i){//日期
            console.log(i);
            if(i && i !==null){
                let nowDate1 = new Date(i[0]);
                let nowYear1 = nowDate1.getFullYear();
                let nowMonth1 = nowDate1.getMonth()+1;
                let nowToday1 = nowDate1.getDate();
                this.startTime1 = nowYear1+'-'+this.fillZero(nowMonth1)+'-'+this.fillZero(nowToday1)
                console.log(this.startTime1);
                let nowDate2 = new Date(i[1]);
                let nowYear2 = nowDate2.getFullYear();
                let nowMonth2 = nowDate2.getMonth()+1;
                let nowToday2 = nowDate2.getDate();
                this.endTime1 = nowYear2+'-'+this.fillZero(nowMonth2)+'-'+this.fillZero(nowToday2)
                console.log(this.endTime1);
            }else{
                this.startTime1 = ''
                this.endTime1 = ''
            }
        },
        changeHandlerDate2(i){//日期
            console.log(i);
            if(i && i !==null){
                let nowDate1 = new Date(i[0]);
                let nowYear1 = nowDate1.getFullYear();
                let nowMonth1 = nowDate1.getMonth()+1;
                let nowToday1 = nowDate1.getDate();
                this.startTime2 = nowYear1+'-'+this.fillZero(nowMonth1)+'-'+this.fillZero(nowToday1)
                console.log(this.startTime2);
                let nowDate2 = new Date(i[1]);
                let nowYear2 = nowDate2.getFullYear();
                let nowMonth2 = nowDate2.getMonth()+1;
                let nowToday2 = nowDate2.getDate();
                this.endTime2 = nowYear2+'-'+this.fillZero(nowMonth2)+'-'+this.fillZero(nowToday2)
                console.log(this.endTime2);
            }else{
                this.startTime2 = ''
                this.endTime2 = ''
            }
        },



        clickTixiang1(){
            this.visibleTixing1 = true
        },
        handleCloseTixing1(){
            this.visibleOut = false
        },
        closeDialogTixing1(){
            this.visibleOut = false
        },




        clickTixiang2(){

        },
   },
}
</script>
<style lang='less' scoped>
.all-box{
    width: 100%;
    height: 100%;
    background-color: #f4f6fc;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .top-all-box-btn{
        width: 100%;
    }
    .all-main-box-all{
        width: 100%;
        flex: 1;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        padding-top: 18px;
        overflow: auto;
        .top-box{
            width: 100%;
            height: 60px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            .p-title{
                width: 300px;
                height: 100%;
                font-size: 30px;
                letter-spacing: 2px;
                display: flex;
                align-items: center;
                padding-left: 20px;
                font-weight: 600;
            }
            .seting-box{
                width: 800px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .btn-box{
                    width: 108px;
                    height: 82%;
                    background-color: #2970ff;
                    margin-right: 30px;
                    border-radius: 6px;
                    color: #fff;
                    letter-spacing: 2px;
                    font-size: 21px;
                    cursor: pointer;
                    transition: all 0.2s linear;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                
            }
        }
        .bom-box{
            width: 100%;
            flex: 1;
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0 4px 8px 0 rgba(30,85,255,.1);
            overflow: auto;
            .head-box{
                width: 100%;
                height: 72px;
                display: flex;
                border-bottom: 1px solid #e8e8e8;
                background-color: #f8fbff;
                .common-lis{
                    width: 20%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding-left: 30px;
                    font-size: 22px;
                    letter-spacing: 1px;
                    .common-main{
                        width: 100%;
                        height: 60%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        border-right: 1px solid #ddd;
                    }
                    .common-main-s{
                        border-right: none;
                    }
                }
                // .common-lis1{
                //     width: 8%;
                //     padding-left: 40px;
                // }
                .common-lis6{
                    width: 20%;
                }
            }
            .table-box{
                width: 100%;
                height: calc(100% - 72px);
                overflow: auto;
                .table-list{
                    width: 100%;
                    height: 100%;
                    .table-list-main{
                        width: 100%;
                        height: 100%;
                        .table-center-all{
                            width: 100%;
                            height: calc(100% - 75px);
                            overflow: auto;
                            .table-lis{
                                width: 100%;
                                height: 70px;
                                display: flex;
                                border-bottom: 1px solid #e8e8e8;
                                background-color: #fff;
                                transition: all 0.2s linear;
                                cursor: pointer;
                                .common-lis{
                                    width: 20%;
                                    height: 70px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    padding-left: 30px;
                                    font-size: 21px;
                                    letter-spacing: 1px;
                                    padding-right: 10px;
                                    span{
                                        white-space: nowrap; //禁止换行
                                        overflow: hidden;
                                        text-overflow: ellipsis; 
                                    }
                                }
                                // .common-lis1{
                                //     width: 8%;
                                //     padding-left: 40px;
                                // }
                                .common-lis6{
                                    width: 20%;
                                    .btn-edit{
                                        padding: 8px 24px;
                                        background-image: linear-gradient(to right, #00c6fb 0%, #005bea 100%);
                                        border-radius: 4px;
                                        color: #fff;
                                        cursor: pointer;
                                        letter-spacing: 2px;
                                        margin-right: 30px;
                                    }
                                    .btn-del{
                                        padding: 8px 24px;
                                        background-image: linear-gradient(120deg, #ecc02f 0%, #fda085 100%);
                                        border-radius: 4px;
                                        color: #fff;
                                        cursor: pointer;
                                        letter-spacing: 2px;
                                    }
                                }
                            }
                            .table-lis:hover{
                                background-color: #f0f7ff;
                            }
                        }
                        .table-center-all::-webkit-scrollbar{display:none}
                        
                        .table-fenye{
                            width: 100%;
                            height: 75px;
                            background-color: #fff;
                            border-top: 1px solid #eaedf3;
                            border-bottom: 1px solid #eaedf3;
                            .fenye-box{
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                            }
                            /deep/ .btn-prev{
                                    color: rgba(0,0,0,.65);
                                    border-color: #d9d9d9;
                                    background-color: #fff;
                                    border: 1px solid #d9d9d9;
                                    border-radius: 5px;
                                    padding: 0px!important;
                                    width: 40px;
                                    height: 44px;
                                    line-height: 44px;
                                    margin-left: 13px;
                                    i{
                                        font-size: 24px!important;
                                        font-weight: 500;
                                        color: rgba(0,0,0,.65);
                                    }
                                }
                                /deep/ .number{
                                    color: rgba(0,0,0,.65);
                                    border-color: #d9d9d9;
                                    background-color: #fff;
                                    border: 1px solid #d9d9d9;
                                    border-radius: 5px;
                                    padding: 0px!important;
                                    min-width: 43px;
                                    padding: 0 6px!important;
                                    height: 44px;
                                    line-height: 44px;
                                    font-size: 22px!important;
                                    font-weight: 500;
                                    color: rgba(0,0,0,.65);
                                    margin-left: 13px;
                                    transition: all 0.2s linear;
                                }
                                /deep/ .number:hover{
                                    border-color: #1890ff;
                                    color: #1890ff;
                                }
                                /deep/ .active{
                                    border-color: #1890ff;
                                    color: #1890ff; 
                                }
                                /deep/ .btn-quickprev{
                                    width: 40px;
                                    height: 44px;
                                    line-height: 44px;
                                }
                                /deep/ .btn-quicknext{
                                    width: 40px;
                                    height: 44px;
                                    line-height: 44px;
                                }
                                /deep/ .btn-next{
                                    color: rgba(0,0,0,.65);
                                    border-color: #d9d9d9;
                                    background-color: #fff;
                                    border: 1px solid #d9d9d9;
                                    border-radius: 5px;
                                    padding: 0px!important;
                                    width: 43px;
                                    height: 44px;
                                    line-height: 44px;
                                    margin-left: 13px;
                                    i{
                                        font-size: 24px!important;
                                        font-weight: 500;
                                        color: rgba(0,0,0,.65);
                                    }
                                }
                                /deep/ .el-pagination__jump{
                                    width: 140px;
                                    height: 44px;
                                    line-height: 44px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-around;
                                }
                                /deep/ .el-input{
                                    width: 60px;
                                    height: 44px;
                                    line-height: 44px;
                                    padding: 0px!important;
                                }
                                /deep/ .el-pagination__editor{
                                    margin: 0px!important;
                                }
                                /deep/ .el-input__inner{
                                    width: 60px;
                                    height: 44px;
                                    line-height: 44px;
                                    padding: 0px!important;
                                    font-size: 22px!important;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                                /deep/ .el-pagination__total{
                                    font-size: 22px;
                                }
                                /deep/ .el-pagination__jump{
                                    font-size: 22px;
                                }
                                /deep/ .el-pagination__sizes{
                                    width: 140px;
                                    height: 50px!important;
                                    .el-select{
                                        height: 100%;
                                        .el-input{
                                            height: 100%;
                                            .el-input__inner{
                                                width: 140px;
                                                height: 100%;
                                                font-size: 22px!important;
                                            }
                                        }
                                    }
                                }
                        }
                        
                    }
                    .kong-box{
                        width: 100%;
                        height: 100%;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        padding-bottom: 160px;
                        .img-kong{
                            width: 400px;
                            height: 300px;
                        }
                        p{
                            text-align: center;
                            line-height: 60px;
                            font-size: 25px;
                            color: #000;
                            letter-spacing: 4px;
                        }
                    }
                }
                .loading-box{
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    position: relative;
                    .content{
                        width: 150px;
                        height: 100px;
                        text-align: center;
                        line-height: 100px;
                        position: absolute;
                        top: 0px;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        margin: auto;
                        div {
                            width: 18px;
                            height: 18px;
                            background-color: blue;
                            border-radius: 100%;
                            display: inline-block;
                            animation: action 1.5s infinite ease-in-out;
                            animation-fill-mode: both;
                            margin-right: 12px;
                        }
                        .point1 {
                            animation-delay: -0.3s;
                        }
                        .point2 {
                            animation-delay: -0.1s;
                        }
                    }
                    @keyframes action {
                        0%, 80%, 100% {
                            transform: scale(0);
                        }
                        40% {
                            transform: scale(1.0);
                        }
                    }
                }
            }
            .table-box::-webkit-scrollbar{display:none}
        }
        
    }
    .mannage-dialog{
        /deep/ .el-dialog__header{
            .el-dialog__title{
                font-size: 26px;
                letter-spacing: 2px;
            }
            .el-dialog__headerbtn{
                .el-dialog__close{
                    font-size: 35px;
                }
            }
        }
        /deep/ .el-dialog__body{
            .text-box{
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;
                .p-ss{
                    font-size: 22px;
                    letter-spacing: 1.3px;
                    line-height: 30px;
                }
                .date-box{
                    width: 100%;
                    height: 50px;
                    margin-top: 20px;
                    .el-range-separator{
                        display: flex;
                        align-items: center;
                        line-height: 50px;
                    }
                    .el-range-input{
                        font-size: 22px;
                        letter-spacing: 0.5px;
                    }
                }
                .p-info{
                    width: 100%;
                    display: flex;
                    border: 1px solid #ddd;
                    border-top: none;
                    .span-left{
                        width: 18%;
                        font-size: 21px;
                        letter-spacing: 2px;
                        padding: 12px;
                        color: #666;
                        display: flex;
                        align-items: center;
                        padding-bottom: 16px;
                        background-color: #f8fbff;
                    }
                    .span-right{
                        width: 80%;
                        font-size: 21px;
                        letter-spacing: 1px;
                        padding: 10px;
                        color: #000;
                        margin-left: 2%;
                        padding-right: 15px;
                        .el-input__inner{
                            border: none!important;
                            box-shadow: none!important;
                            height: auto!important;
                            padding: 0px!important;
                            font-size: 21px;
                            color: #000;
                            letter-spacing: 1px;
                            
                        }
                    }
                }
                .p-info:first-child{
                    border-top: 1px solid #ddd;
                }
            }
        }    
    }   
}
</style>