<template>
    <div class="btn-all-top-box">
        <div class="mokuai-box">
            <div class="mokuai-lis">
                <div class="top-box">
                    <p class="p-title">审批提醒</p>
                </div>
                <div class="mokuai-bom">
                    <div class="btn-anniu" @click="clickTixing1">出差审批提醒</div>
                    <div class="btn-anniu" @click="clickTixing2">加班审批提醒</div>
                </div>
            </div>
        </div>
        <div class="mokuai-box">
            <div class="mokuai-lis">
                <div class="top-box">
                    <p class="p-title">刷取数据</p>
                </div>
                <div class="mokuai-bom">
                    <div class="btn-anniu shuaqu" @click="clickShua1">刷取钉钉出差申请</div>
                    <div class="btn-anniu shuaqu" @click="clickShua2">刷取钉钉加班申请</div>
                </div>
            </div>
        </div>
        <div class="mokuai-box">
            <div class="mokuai-lis">
                <div class="top-box">
                    <p class="p-title">手动推送待办</p>
                </div>
                <div class="mokuai-bom">
                    <div class="btn-anniu tuisong" @click="clickPush1">推送出差</div>
                    <div class="btn-anniu tuisong" @click="clickPush2">推送加班</div>
                </div>
            </div>
        </div>
        <el-dialog class="mannage-dialog" title="出差审批提醒" :visible.sync="showTixing1" width="35%" :before-close="handleCloseTixing1">
            <div class="text-box">
                <p class="p-ss">请选择日期区间:</p>
                <el-date-picker class="date-box" v-model="dateTixing1" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="changeDateTixing1"></el-date-picker>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleCloseTixing1">取 消</el-button>
                <el-button type="primary" @click="clickOkTixing1">确 定</el-button>
            </span> 
        </el-dialog>

        <el-dialog class="mannage-dialog" title="加班审批提醒" :visible.sync="showTixing2" width="35%" :before-close="handleCloseTixing2">
            <div class="text-box">
                <p class="p-ss">请选择日期区间:</p>
                <el-date-picker class="date-box" v-model="dateTixing2" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="changeDateTixing2"></el-date-picker>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleCloseTixing2">取 消</el-button>
                <el-button type="primary" @click="clickOkTixing2">确 定</el-button>
            </span> 
        </el-dialog>

        <el-dialog class="mannage-dialog" title="刷取钉钉出差申请" :visible.sync="showShua1" width="35%" :before-close="handleCloseShua1">
            <div class="text-box">
                <p class="p-ss">请选择日期区间:</p>
                <el-date-picker class="date-box" v-model="dateShua1" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="changeDateShua1"></el-date-picker>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleCloseShua1">取 消</el-button>
                <el-button type="primary" @click="clickOkShua1">确 定</el-button>
            </span> 
        </el-dialog>

        <el-dialog class="mannage-dialog" title="刷取钉钉加班申请" :visible.sync="showShua2" width="35%" :before-close="handleCloseShua2">
            <div class="text-box">
                <p class="p-ss">请选择日期区间:</p>
                <el-date-picker class="date-box" v-model="dateShua2" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="changeDateShua2"></el-date-picker>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleCloseShua2">取 消</el-button>
                <el-button type="primary" @click="clickOkShua2">确 定</el-button>
            </span> 
        </el-dialog>


        <el-dialog class="mannage-dialog" title="推送出差待办" :visible.sync="showPush1" width="35%" :before-close="handleClosePush1">
            <div class="text-box">
                <div class="btn-add" @click="addDaiban1">
                    <img src="../../assets/imgs/img-add-ss.png" alt="">新建
                </div>
                 <el-table :data="formList1" style="width: 99.99%">
                    <el-table-column prop="name" label="待办ID" width="350">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.name" autocomplete="off" placeholder="请输入待办ID"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="150">
                      <template slot-scope="scope">
                        <el-button class="btn-del-box"  type="danger" plain @click="delRows1(scope.$index, scope.row)">删除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClosePush1">取 消</el-button>
                <el-button type="primary" @click="clickOkPush1">确 定</el-button>
            </span> 
        </el-dialog>

        <el-dialog class="mannage-dialog" title="推送加班待办" :visible.sync="showPush2" width="35%" :before-close="handleClosePush2">
            <div class="text-box">
                <div class="btn-add" @click="addDaiban2">
                    <img src="../../assets/imgs/img-add-ss.png" alt="">新建
                </div>
                 <el-table :data="formList2" style="width: 99.99%">
                    <el-table-column prop="name" label="待办ID" width="350">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.name" autocomplete="off" placeholder="请输入待办ID"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="150">
                      <template slot-scope="scope">
                        <el-button class="btn-del-box"  type="danger" plain @click="delRows2(scope.$index, scope.row)">删除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClosePush2">取 消</el-button>
                <el-button type="primary" @click="clickOkPush2">确 定</el-button>
            </span> 
        </el-dialog>
    </div>
</template>
        
<script>
import * as api from "../../api"
export default {
   data() {
    return {
        // 出差审批提醒
        showTixing1:false,
        dateTixing1:'',
        startTimeTixing1:'',
        endTimeTixing1:'',

        // 加班审批提醒
        showTixing2:false,
        dateTixing2:'',
        startTimeTixing2:'',
        endTimeTixing2:'',

        // 刷取钉钉出差申请
        showShua1:false,
        dateShua1:'',
        startTimeShua1:'',
        endTimeShua1:'',

        // 刷取钉钉加班申请
        showShua2:false,
        dateShua2:'',
        startTimeShua2:'',
        endTimeShua2:'',


        showPush1:false,
        showPush2:false,

        formList1:[{id:'hjh',name:''}],
        formList2:[{id:'hjh-jaban',name:''}],

        

        msgTixing1:{},
        msgTixing2:{},
        msgShua1:{},
        msgShua2:{},
        msgPush1:{},
        msgPush2:{},
    }
   },
   mounted() {
    
   },
   methods: {
        fillZero(str){//日期补全
            var realNum;
            if(str<10){
                realNum	= '0'+str;
            }else{
                realNum	= str;
            }
            return realNum;
        },
        //出差审批提醒功能  批量发送钉钉待办 -----------------------
        clickTixing1(){//点击按钮
            this.showTixing1 = true
        },
        changeDateTixing1(i){//选择日期
            console.log(i);
            if(i && i !==null){
                let nowDate1 = new Date(i[0]);
                let nowYear1 = nowDate1.getFullYear();
                let nowMonth1 = nowDate1.getMonth()+1;
                let nowToday1 = nowDate1.getDate();
                this.startTimeTixing1 = nowYear1+'-'+this.fillZero(nowMonth1)+'-'+this.fillZero(nowToday1)
                console.log(this.startTimeTixing1);
                let nowDate2 = new Date(i[1]);
                let nowYear2 = nowDate2.getFullYear();
                let nowMonth2 = nowDate2.getMonth()+1;
                let nowToday2 = nowDate2.getDate();
                this.endTimeTixing1 = nowYear2+'-'+this.fillZero(nowMonth2)+'-'+this.fillZero(nowToday2)
                console.log(this.endTimeTixing1);
            }else{
                this.startTimeTixing1 = ''
                this.endTimeTixing1 = ''
            }
        },
        handleCloseTixing1(){//dialog关闭按钮
            this.showTixing1 = false
            this.dateTixing1 = ''
            this.startTimeTixing1 = ''
            this.endTimeTixing1 = ''
        },
        clickOkTixing1(){//dialog确定按钮
            if(this.startTimeTixing1 === '' || this.endTimeTixing1 === ''){
                this.$message.warning('请先选择日期区间！')
            }else{
                this.msgTixing1 = this.$message({
                    duration: 0,
                    message: '正在发送出差审批提醒....'
                })
                api.getSendOutApi(this.startTimeTixing1,this.endTimeTixing1).then(res=>{
                    console.log(res.data);
                    if(res){
                        if(res.data.data === "操作成功"){
                            this.msgTixing1.close() 
                            this.$message.success('出差审批提醒成功！')
                            this.showTixing1 = false
                            this.dateTixing1 = ''
                            this.startTimeTixing1 = ''
                            this.endTimeTixing1 = ''
                        }else{
                            this.msgTixing1.close() 
                            this.$message.error('出差审批提醒失败！')
                        }
                    }else{
                        
                    }
                }).catch(err=>{
                    this.msgTixing1.close() 
                    this.$message.warning('数据量过多，正在后台推送！');
                    this.showTixing1 = false
                    this.dateTixing1 = ''
                    this.startTimeTixing1 = ''
                    this.endTimeTixing1 = ''
                })
            }
            
        },
        //加班审批提醒功能  批量发送钉钉待办 -----------------------
        clickTixing2(){//点击按钮
            this.showTixing2 = true
        },
        changeDateTixing2(i){//选择日期
            console.log(i);
            if(i && i !==null){
                let nowDate1 = new Date(i[0]);
                let nowYear1 = nowDate1.getFullYear();
                let nowMonth1 = nowDate1.getMonth()+1;
                let nowToday1 = nowDate1.getDate();
                this.startTimeTixing2 = nowYear1+'-'+this.fillZero(nowMonth1)+'-'+this.fillZero(nowToday1)
                console.log(this.startTimeTixing2);
                let nowDate2 = new Date(i[1]);
                let nowYear2 = nowDate2.getFullYear();
                let nowMonth2 = nowDate2.getMonth()+1;
                let nowToday2 = nowDate2.getDate();
                this.endTimeTixing2 = nowYear2+'-'+this.fillZero(nowMonth2)+'-'+this.fillZero(nowToday2)
                console.log(this.endTimeTixing2);
            }else{
                this.startTimeTixing2 = ''
                this.endTimeTixing2 = ''
            }
        },
        handleCloseTixing2(){//dialog关闭按钮
            this.showTixing2 = false
            this.dateTixing2 = ''
            this.startTimeTixing2 = ''
            this.endTimeTixing2 = ''
        },
        clickOkTixing2(){//dialog确定按钮
            if(this.startTimeTixing2 === '' || this.endTimeTixing2 === ''){
                this.$message.warning('请先选择日期区间！')
            }else{
                this.msgTixing2 = this.$message({
                    duration: 0,
                    message: '正在发送加班审批提醒....'
                })
                api.getSendOverApi(this.startTimeTixing2,this.endTimeTixing2).then(res=>{
                    console.log(res.data);
                    if(res){
                        if(res.data.data === "操作成功"){
                            this.msgTixing2.close() 
                            this.$message.success('加班审批提醒成功！')
                            this.showTixing2 = false
                            this.dateTixing2 = ''
                            this.startTimeTixing2 = ''
                            this.endTimeTixing2 = ''
                        }else{
                            this.msgTixing2.close() 
                            this.$message.error('加班审批提醒失败！')
                        }
                    }
                }).catch(err=>{
                    this.msgTixing2.close() 
                    this.$message.warning('数据量过多，正在后台推送！');
                        this.showTixing2 = false
                        this.dateTixing2 = ''
                        this.startTimeTixing2 = ''
                        this.endTimeTixing2 = ''
                })
            }
            
        },

        //刷取钉钉出差申请 -----------------------
        clickShua1(){//点击按钮
            this.showShua1 = true
        },
        changeDateShua1(i){//选择日期
            console.log(i);
            if(i && i !==null){
                let nowDate1 = new Date(i[0]);
                let nowYear1 = nowDate1.getFullYear();
                let nowMonth1 = nowDate1.getMonth()+1;
                let nowToday1 = nowDate1.getDate();
                this.startTimeShua1 = nowYear1+'-'+this.fillZero(nowMonth1)+'-'+this.fillZero(nowToday1)
                console.log(this.startTimeShua1);
                let nowDate2 = new Date(i[1]);
                let nowYear2 = nowDate2.getFullYear();
                let nowMonth2 = nowDate2.getMonth()+1;
                let nowToday2 = nowDate2.getDate();
                this.endTimeShua1 = nowYear2+'-'+this.fillZero(nowMonth2)+'-'+this.fillZero(nowToday2)
                console.log(this.endTimeShua1);
            }else{
                this.startTimeShua1 = ''
                this.endTimeShua1 = ''
            }
        },
        handleCloseShua1(){//dialog关闭按钮
            this.showShua1 = false
            this.dateShua1 = ''
            this.startTimeShua1 = ''
            this.endTimeShua1 = ''
        },
        clickOkShua1(){//dialog确定按钮
            if(this.startTimeShua1 === '' || this.endTimeShua1 === ''){
                this.$message.warning('请先选择日期区间！')
            }else{
                this.msgShua1 = this.$message({
                    duration: 0,
                    message: '正在刷取钉钉出差申请....'
                })
                api.getOutShuaApi(this.startTimeShua1,this.endTimeShua1).then(res=>{
                    console.log(res.data);
                    if(res){
                        if(res.data.msg === "操作成功"){
                            this.msgShua1.close() 
                            this.$message.success('刷取钉钉出差申请成功！')
                            this.showShua1 = false
                            this.dateShua1 = ''
                            this.startTimeShua1 = ''
                            this.endTimeShua1 = ''
                        }else{
                            this.msgShua1.close() 
                            this.$message.error('刷取钉钉出差申请失败！')
                        }
                    }
                }).catch(err=>{
                    this.msgShua1.close() 
                    this.$message.warning('数据量过多，正在后台刷取！');
                    this.showShua1 = false
                    this.dateShua1 = ''
                    this.startTimeShua1 = ''
                    this.endTimeShua1 = ''
                })
            }
            
        },
        //刷取钉钉加班申请 -----------------------
        clickShua2(){//点击按钮
            this.showShua2 = true
        },
        changeDateShua2(i){//选择日期
            console.log(i);
            if(i && i !==null){
                let nowDate1 = new Date(i[0]);
                let nowYear1 = nowDate1.getFullYear();
                let nowMonth1 = nowDate1.getMonth()+1;
                let nowToday1 = nowDate1.getDate();
                this.startTimeShua2 = nowYear1+'-'+this.fillZero(nowMonth1)+'-'+this.fillZero(nowToday1)
                console.log(this.startTimeShua2);
                let nowDate2 = new Date(i[1]);
                let nowYear2 = nowDate2.getFullYear();
                let nowMonth2 = nowDate2.getMonth()+1;
                let nowToday2 = nowDate2.getDate();
                this.endTimeShua2 = nowYear2+'-'+this.fillZero(nowMonth2)+'-'+this.fillZero(nowToday2)
                console.log(this.endTimeShua2);
            }else{
                this.startTimeShua2 = ''
                this.endTimeShua2 = ''
            }
        },
        handleCloseShua2(){//dialog关闭按钮
            this.showShua2 = false
            this.dateShua2 = ''
            this.startTimeShua2 = ''
            this.endTimeShua2 = ''
        },
        clickOkShua2(){//dialog确定按钮
            if(this.startTimeShua2 === '' || this.endTimeShua2 === ''){
                this.$message.warning('请先选择日期区间！')
            }else{
                this.msgShua2 = this.$message({
                    duration: 0,
                    message: '正在刷取钉钉加班申请....'
                })
                api.getOverShuaApi(this.startTimeShua2,this.endTimeShua2).then(res=>{
                    console.log(res.data);
                    if(res){
                        if(res.data.length > 0){
                            this.msgShua2.close() 
                            this.$message.success('刷取钉钉加班申请成功！')
                            this.showShua2 = false
                            this.dateShua2 = ''
                            this.startTimeShua2 = ''
                            this.endTimeShua2 = ''
                        }else{
                            this.msgShua2.close() 
                            this.$message.error('刷取钉钉加班申请失败！暂无刷取数据')
                        }
                    }
                }).catch(err=>{
                    this.msgShua2.close() 
                    this.$message.warning('数据量过多，正在后台刷取！');
                    this.showShua2 = false
                    this.dateShua2 = ''
                    this.startTimeShua2 = ''
                    this.endTimeShua2 = ''
                })
            }
            
        },


        //推送待办 -----------------------
        // 出差
        clickPush1(){
            this.showPush1 = true
        },
        handleClosePush1(){
            this.showPush1 = false
            this.formList1 = []
            this.addDaiban1()
        },
        addDaiban1(){
            let length = this.formList1.length;
            this.formList1.push({
                id: parseInt(length) + 1,
                name: "",
            });
        },
        clickOkPush1(){
            console.log(this.formList1);
            if(this.formList1.length === 1 && this.formList1[0].name === ''){
                this.$message.warning('请先输入推送待办ID！')
            }else{
                let params = []
                this.formList1.forEach(item=>{
                    params.push(item.name)
                })
                this.msgPush1 = this.$message({
                    duration: 0,
                    message: '正在推送出差待办....'
                })
                console.log(params);
                api.getPushChuchaiApi(params).then(res=>{
                    console.log(res.data,"aaa");
                    if(res){
                        if(res.data.msg === "操作成功"){
                            this.msgPush1.close() 
                            this.$message.success('推送出差待办成功！')
                            this.showPush1 = false
                            this.formList1 = []
                            this.addDaiban1()
                        }else{
                            this.msgPush1.close() 
                            this.$message.error('推送出差待办失败！')
                        }
                    }
                }).catch(err=>{
                    this.msgPush1.close() 
                    this.$message.warning('数据量过多，正在后台推送！');
                    this.showPush1 = false
                    this.formList1 = []
                    this.addDaiban1()
                })
            }
        },
        delRows1(index, row) {
            for (var i = 0; i < this.formList1.length; i++) {
                if (row.id == this.formList1[i].id) {
                    this.formList1.splice(i, 1);
                }
            }
        },  

        // 加班
        clickPush2(){
            this.showPush2 = true
        },
        handleClosePush2(){
            this.showPush2 = false
            this.formList2 = []
            this.addDaiban2()
        },
        addDaiban2(){
            let length = this.formList2.length;
            this.formList2.push({
                id: parseInt(length) + 1,
                name: "",
            });
        },
        clickOkPush2(){
            console.log(this.formList2);
            if(this.formList2.length === 1 && this.formList2[0].name === ''){
                this.$message.warning('请先输入推送待办ID！')
            }else{
                let params = []
                this.formList2.forEach(item=>{
                    params.push(item.name)
                })
                this.msgPush2 = this.$message({
                    duration: 0,
                    message: '正在推送加班待办....'
                })
                console.log(params);
                api.getPushJiabanApi(params).then(res=>{
                    console.log(res.data,"aaa");
                    if(res){
                        if(res.data.msg === "操作成功"){
                            this.msgPush2.close() 
                            this.$message.success('推送加班待办成功！')
                            this.showPush2 = false
                            this.formList2 = []
                            this.addDaiban2()
                        }else{
                            this.msgPush2.close() 
                            this.$message.error('推送加班待办失败！')
                        }
                    }
                }).catch(err=>{
                    this.msgPush2.close() 
                    this.$message.warning('数据量过多，正在后台推送！');
                    this.showPush2 = false
                    this.formList2 = []
                    this.addDaiban2()
                })
            }
        },
        delRows2(index, row) {
            for (var i = 0; i < this.formList2.length; i++) {
                if (row.id == this.formList2[i].id) {
                    this.formList2.splice(i, 1);
                }
            }
        },  
        
   }
}
</script>
<style lang='less' scoped>
.btn-all-top-box{
    width: 100%;
    display: flex;
    .mokuai-box{
        height: 100%;
        background-color: #fff;
        border-radius: 6px;
        padding:10px 0px 26px 10px;
        display: flex;
        flex-direction: column;
        margin-right: 35px;
        .top-box{
            width: 100%;
            height: 60px;
            display: flex;
            justify-content: space-between;
            .p-title{
                width: 300px;
                height: 100%;
                font-size: 30px;
                letter-spacing: 2px;
                display: flex;
                align-items: center;
                padding-left: 15px;
                font-weight: 600;
            }
        }
        .mokuai-bom{
            width: 100%;
            flex: 1;
            display: flex;
            align-items: center;
            padding-top: 10px;
            padding-left: 15px;
            .btn-anniu{
                display: inline-block;
                padding: 15px 35px;
                background-color: rgb(42,161,243);
                font-size: 22px;
                letter-spacing: 1.5px;
                color: #fff;
                margin-right: 50px;
                border-radius: 6px;
                cursor: pointer;
                box-shadow: none;
                transition: all 0.2s linear;
            }
            .shuaqu{
                background-color: rgb(26,198,190);
            }
            .tuisong{
                background-color: rgb(241,130,33);
            }
            .btn-anniu:hover{
                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
            }
        }
    }
    .mannage-dialog{
        /deep/ .el-dialog__header{
            .el-dialog__title{
                font-size: 26px;
                letter-spacing: 2px;
            }
            .el-dialog__headerbtn{
                .el-dialog__close{
                    font-size: 35px;
                }
            }
        }
        /deep/ .el-dialog__body{
            max-height: 800px;
            .text-box{
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;
                .p-ss{
                    font-size: 22px;
                    letter-spacing: 1.3px;
                    line-height: 30px;
                }
                .date-box{
                    width: 100%;
                    height: 50px;
                    margin-top: 20px;
                    .el-range-separator{
                        display: flex;
                        align-items: center;
                        line-height: 50px;
                    }
                    .el-range-input{
                        font-size: 22px;
                        letter-spacing: 0.5px;
                    }
                }
                .p-info{
                    width: 100%;
                    display: flex;
                    border: 1px solid #ddd;
                    border-top: none;
                    .span-left{
                        width: 18%;
                        font-size: 21px;
                        letter-spacing: 2px;
                        padding: 12px;
                        color: #666;
                        display: flex;
                        align-items: center;
                        padding-bottom: 16px;
                        background-color: #f8fbff;
                    }
                }
                .btn-add{
                    width: 150px;
                    height: 52px;
                    background-color: #007FFF;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 22px;
                    letter-spacing: 2px;
                    border-radius: 6px;
                    cursor: pointer;
                    transition: all 0.2s linear;
                    margin-bottom: 20px;
                    img{
                        width: 22px;
                        height: 22px;
                        margin-right: 10px;
                    }
                }
                .btn-add:hover{
                    background-color: #419af3;
                }
                .span-right{
                        width: 100%;
                        font-size: 21px;
                        letter-spacing: 1px;
                        padding: 10px;
                        color: #000;
                        // margin-left: 2%;
                        padding-right: 15px;
                        border: 1px solid #ddd;
                        margin-top: 20px;
                        .el-input__inner{
                            border: none!important;
                            box-shadow: none!important;
                            height: auto!important;
                            padding: 0px!important;
                            font-size: 21px;
                            color: #000;
                            letter-spacing: 1px;
                        }
                    }
                .p-info:first-child{
                    border-top: 1px solid #ddd;
                }
            }
            .el-table{
                .el-table__header{
                    // height: 60px!important;
                    .el-table__cell{
                        .cell{
                            font-size: 22px;
                            font-weight: normal;
                            color: #333;
                        }
                    }
                }
                .el-table__body-wrapper{
                    max-height: 600px;
                    overflow: auto;
                }
                .el-table__body-wrapper::-webkit-scrollbar{display:none}
                .el-table__body{
                    .el-input__inner{
                            // border: none!important;
                            // box-shadow: none!important;
                        height: 50px!important;
                            // padding: 0px!important;
                        font-size: 21px;
                        color: #000;
                        letter-spacing: 1px;
                    }
                    .btn-del-box{
                        height: 50px;
                        padding-left: 50px;
                        padding-right: 50px;
                        transition: all 0.2s linear;
                    }
                }
            }
        }    
    }  
}
</style>